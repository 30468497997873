import { useMemberships } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { IMembership } from '@guider-global/shared-types';
import { AvatarBox, Button } from '@guider-global/ui';
import { FormHelperText } from '@mui/material';
import { Stack } from '@mui/system';
import traineeIcon from 'assets/learn.svg';
import guideIcon from 'assets/users.svg';
import { ProgressContainer } from 'containers';
import { interpolate } from 'functions';
import { useLocalization } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import GoalCategoriesView from 'views/Registration/GoalCategoriesView';

export function ProgramGoalsPage() {
  const [selectedGoalCategories, setSelectedGoalCategories] = useState<
    string[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);

  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const dispatch = useAppDispatch();

  const { organization } = useOrganization({
    organizationSlug,
  });

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({
    localeCode,
  });

  // Program
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  const min = program?.registration?.goals?.trainee_min_selected_goals;
  const max = program?.registration?.goals?.trainee_max_selected_goals;
  const errorMessage =
    baseLanguage?.globals?.goals?.max_goals_validation_message;

  const limitNotMet = Boolean(
    selectedGoalCategories?.length === 0 ||
      (min && selectedGoalCategories?.length < min),
  );
  const limitExceeded = Boolean(max && selectedGoalCategories?.length > max);

  // Memberships
  const { memberships, reqMemberships, isMutatingMemberships } = useMemberships(
    {},
  );

  const membership = memberships?.find(
    (membership) =>
      membership?.programSlug === programSlug && membership?.role === role,
  );

  useEffect(() => {
    if (!membership || loading || selectedGoalCategories?.length !== 0) return;

    if (membership?.goalCategories) {
      setSelectedGoalCategories(
        membership?.goalCategories
          ?.filter((goalCategory) =>
            role === 'guide' ? goalCategory?.offered : goalCategory?.sought,
          )
          ?.map((goalCategory) => goalCategory?.fieldSlug) ?? [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership, role]);

  const goalsSource =
    program?.registration?.goals?.custom_goal_categories ??
    organization?.goal_categories?.categories;

  const goalCategories = goalsSource?.map((goalCategory) => ({
    label: goalCategory?.goal_category_name,
    slug: goalCategory?.goal_category_slug?.current,
  }));

  async function handleGoalsSubmit() {
    if (!membership || loading) return;

    setLoading(true);

    const data: Partial<IMembership> & { goalCategorySlugs: string[] } = {
      role,
      isPublished: membership?.isPublished,
      programSlug,
      goalCategorySlugs: selectedGoalCategories,
    };

    const membershipsResult = await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${membership?.id}`,
      data,
    });

    if (membershipsResult?.status === 'success') {
      navigate('../skills/soft');
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: membershipsResult?.message,
          timeout: 3000,
        }),
      );
    }
    setLoading(false);
  }

  if (!membership)
    return (
      <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={40}>
        {' '}
        <></>{' '}
      </ProgressContainer>
    );

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={40}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
      >
        <>
          <AvatarBox
            orientation="vertical"
            textSpacing={1.5}
            size="medium"
            heading={{
              text:
                role === 'guide'
                  ? interpolate(
                      baseLanguage?.registration?.open_matching?.goals
                        ?.title_guide,
                      {
                        traineeSingular:
                          organization?.open_matching?.program_type
                            ?.program_type_text?.common?.trainee?.singular ??
                          'Mentee',
                      },
                    )
                  : interpolate(
                      baseLanguage?.registration?.open_matching?.goals
                        ?.title_trainee,
                      {
                        guideSingular:
                          organization?.open_matching?.program_type
                            ?.program_type_text?.common?.guide?.singular ??
                          'Mentor',
                      },
                    ),

              variant: 'h2',
              lineHeight: '115%',
            }}
            subtitles={[
              {
                text: baseLanguage?.registration?.open_matching?.goals
                  ?.select_number_of_goals,
                color: 'text.secondary',
                variant: 'subtitle1',
              },
            ]}
            avatarSrc={role === 'guide' ? guideIcon : traineeIcon}
            avatarSx={{
              p: 1.25,
              border: 'none',
              backgroundColor: '#F5F5F5',
            }}
          />
          {goalCategories && (
            <GoalCategoriesView
              key={'program-goals'}
              goalCategories={goalCategories}
              onSelected={(slugs) => setSelectedGoalCategories(slugs)}
              buttonLabels={{
                more: baseLanguage?.globals?.common?.show_more_button_label,
                less: baseLanguage?.globals?.common?.show_less_button_label,
              }}
              selectedGoalCategories={selectedGoalCategories}
            />
          )}
        </>
        <Button
          variant="contained"
          label={baseLanguage?.globals?.common?.continue_button_label}
          color="info"
          onClick={() => handleGoalsSubmit()}
          data-cy="register-goals-continue-button"
          loading={loading || isMutatingMemberships}
          disabled={limitExceeded || limitNotMet}
        />
        {limitExceeded && (
          <FormHelperText sx={{ color: 'error.main', mt: 2 }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Stack>
    </ProgressContainer>
  );
}
